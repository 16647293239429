// Desktop form styles
.CandidateForm {
	margin-left: 24px;

	&__EEO {

		&Heading {
			align-items: center;
			display: flex;
			fab-font-weight: semibold;
		}

		&Paragraph {
			fab-text: small;
		}

		&Icon {
			fab-fill: gray6;
			line-height: 0;
			margin-left: 4px;
		}
	}
}

.CandidateField {
	&__inputWrapper {
		width: 100%;

		&--firstName {
			fab-width: 8;
		}

		&--lastName {
			fab-width: 8;
		}

		&--email {
			fab-width: 9;
		}

		&--phone {
			fab-width: 5;
		}

		&--streetAddress {
			fab-width: 9;
		}

		&--city {
			fab-width: 6;
		}

		&--zip {
			fab-width: 3;
		}

		&--sourceId {
			fab-width: 7;
		}

		&--dateAvailable {
			fab-width: 5;
		}

		&--desiredPay {
			fab-width: 5;
		}

		&--websiteUrl {
			fab-width: 9;
		}

		&--linkedinUrl {
			fab-width: 9;
		}

		&--twitterUsername {
			fab-width: 6;
		}

		&--educationInstitutionName {
			fab-width: 6;
		}

		&--referredBy {
			fab-width: 9;
		}

		&--references {
			fab-width: 9;
		}

		&--genderId {
			fab-width: 6;
		}

		&--ethnicityId {
			fab-width: 8;
		}

		&--disabilityId {
			fab-width: 8;
		}

		&--cqShort {
			fab-width: 9;
		}

		&--cqLong {
			fab-width: 10;
		}
	}

	&__multi {
		display: inline-flex;
		align-items: center;
		&Select {
			fab-width: 9;
		}

		&Group {
			&Column {
				width: 100%;
				> div:focus-within {
					overflow: visible !important; // SlideTransition wrapper
				}
			}
			&--label {
				height: auto;
			}
		}

		&Other {
			fab-width: 7;
			margin-top: 8px;
			&Select {
				fab-width: 9;
				margin-top: 4px;
			}
		}

		&Input {
			&Wrapper {
				width: 100%;
				left: -2px;
				padding: 2px;
				top: -2px;
			}
		}
	}



	&__checkboxErrorIcon {
		fab-fill: danger;
		line-height: 0;
		margin-right: 5px;
	}

	&__checkboxRequiredIndicator {
		fab-color: theme;
		font-family: sans-serif;
		fab-font-size: large;
		margin-left: 3px;
	}

	&--veteranStatuses {
		opacity: 0;
		fab-width: 8;
		margin-left: 24px;
		min-height: 54px;

		&Shown {
			opacity: 1;
		}
	}
}
